import DropdownSelect from '~/components/DropdownSelect'
import { LinkButton } from '~/components/Button'
import GeolocationDropdownSelect from '~/components/GeolocationDropdownSelect/GeolocationDropdownSelectGql'
import { MODAL_NAMES } from '../../utils/consts'
import { getIsFestivalPurchaseFlowNewClient, getNewClientListingLink } from '../../utils/newClient'
import { pushUIEvent } from '../../utils/gtag'

export default ({
  people,
  peopleOptions,
  loading,
  setLoading,
  setPeopleValue,
  buttonLabel,
  locationModifiers,
  peopleModifiers,
  buttonModifiers,
  festivalData,
  festivalName,
  redirectParams,
  iconRight,
  isPresale,
  openModal
}) => {

  const handleChange = (value) => {
    if (value.value === 'extra') {
      openModal && openModal(MODAL_NAMES.EXTRA_GUEST)
      return
    }
    setPeopleValue(value)
  }

  return (
    <div className='hero-filters'>
      <div className='hero-filters__dropdowns'>
        <GeolocationDropdownSelect
          instanceId='location-select-hero'
          modifiers={locationModifiers}
          disabled={festivalData === null}
        />
        <DropdownSelect
          modifiers={peopleModifiers}
          valueContainerIcon='user'
          options={peopleOptions}
          defaultValue={people}
          value={people}
          onChange={(value) => handleChange(value)}
          isSearchable={false}
          hideSelectedOptions={false}
          disabled={festivalData === null}
        />
      </div>
      <LinkButton
        modifiers={['primary', buttonModifiers]}
        route={getIsFestivalPurchaseFlowNewClient(festivalName)
          ? getNewClientListingLink(festivalName, redirectParams)
          : isPresale ? 'early-reservation' : 'restaurants'}
        params={redirectParams}
        label={buttonLabel}
        onClick={() => {
          pushUIEvent({
            event: 'listing',
            eventAction: 'navigate',
            data: {
              'peopleCount': people.value,
              'location': redirectParams.location
            }
          })
          setLoading()
        }}
        loading={loading}
        disabled={loading || festivalData === null}
        iconRight={iconRight}
      />
    </div>
  )
}
